/*
-----------------------------
Header
-----------------------------
*/
.main-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	.top {
		background-color: rgba(0, 0, 0, 0.1);
		padding: 5px 0;
		a {
			color: #fff;
			font-size: 14px;
			letter-spacing: 1px;
			display: inline;
			margin-left: 30px;
			text-decoration: none;
		}
	}
	.navbar {
		padding: 25px 0;
		.contw {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			nav {
				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
					display: flex;
					align-items: center;
					li {
						margin-left: 30px;
						a {
							font-family: $bold;
							font-size: 14px;
							letter-spacing: 1px;
							color: #fff;
							text-decoration: none;
							display: block;
							padding: 10px 0;
						}
						&.action {
							a {
								@extend .btn;
								@extend .btn-solid;
								font-size: 14px;
							}
						}
						&.dropmenu {
							position: relative;
							.submenu {
								position: absolute;
								top: calc(100% + 30px);
								left: -60px;
								z-index: 100;
								width: 380px;
								list-style-type: none;
								margin: 0;
								display: flex;
								flex-direction: column;
								background: #fff;
								box-shadow: 4px 10px 20px
									rgba(160, 164, 167, 0.27);
								border-radius: 30px;
								padding: 30px 30px 1px;

								opacity: 0;
								visibility: hidden;
								transition: all 0.4s
									cubic-bezier(0.22, 1, 0.36, 1);
								transform: translateY(15px) scale(0.97);

								&::before {
									content: "";
									display: block;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 0 15px 20px 15px;
									border-color: transparent transparent #fff
										transparent;
									position: absolute;
									top: -20px;
									left: 100px;
								}
								&::after {
									content: "";
									display: block;
									width: 100%;
									height: 30px;
									position: absolute;
									top: -30px;
									left: 0;
									// background-color: royalblue;
								}
								li {
									display: block;
									width: 100%;
									margin: 0;
									padding: 0;
									a {
										color: $fontcolor;
										display: block;
										position: relative;
										padding: 0;
										padding-left: 55px;
										margin-bottom: 30px;
										img {
											position: absolute;
											top: 0;
											left: 0;
										}
										div {
											text-transform: uppercase;
											letter-spacing: 0.15em;
										}
										span {
											opacity: 0.5;
											font-size: 13px;
										}
										&:hover {
											div {
												color: $naranja;
											}
											span {
												opacity: 0.6;
											}
										}
									}
								}
							}
							&:hover {
								.submenu {
									opacity: 1;
									visibility: visible;
									transform: translateY(0) scale(1);
								}
							}
						}
					}
				}
			}
			.mobile-toggle {
				.hamburger {
					outline: none;
					.hamburger-label {
						color: #fff;
						font-size: 14px;
						font-family: $nbold;
						text-transform: uppercase;
						margin-left: 10px;
						position: relative;
						top: -5px;
						letter-spacing: 0.015em;
					}
				}
			}
		}
	}
	&.alt {
		.navbar {
			.logo {
				svg {
					path {
						fill: $naranja;
					}
				}
			}
		}
	}
	&.menu-dark {
		.top {
			a {
				color: $fontcolor;
				svg {
					path {
						fill: $fontcolor;
					}
				}
			}
		}
		.navbar {
			.contw {
				nav {
					ul {
						li {
							a {
								color: $fontcolor;
							}
							&.action {
								a {
									color: #fff;
									background-color: $naranja;
									&:hover {
										background-color: lighten($naranja, 5%);
									}
								}
							}
						}
					}
				}
				.mobile-toggle {
					.hamburger {
						.hamburger-box {
							.hamburger-inner {
								background-color: $fontcolor;
								&::before,
								&::after {
									background-color: $fontcolor;
								}
							}
						}
						.hamburger-label {
							color: $fontcolor;
						}
					}
				}
			}
		}
	}

	.menu-overlay {
		position: absolute;
		width: 420px;
		top: 100%;
		right: 15px;
		background: #fff;
		box-shadow: 4px 10px 20px rgba(160, 164, 167, 0.27);
		border-radius: 20px;
		padding: 20px;
		margin-left: 15px;

		opacity: 0;
		visibility: hidden;
		transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
		transform: translateY(20px) scale(0.97);

		&::before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 15px 20px 15px;
			border-color: transparent transparent #fff transparent;
			position: absolute;
			top: -20px;
			right: 62px;
		}
		nav {
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					margin: 15px 0;
					a {
						font-family: $bold;
						font-size: 14px;
						letter-spacing: 1px;
						color: $fontcolor;
						text-decoration: none;
						display: block;
					}
					&.action {
						a {
							@extend .btn;
							@extend .btn-solid;
							@extend .d-block;
							font-size: 14px;
						}
					}
					ul {
						padding-left: 15px;
						margin-top: 10px;
						li {
							margin: 0 0 15px 0;
							a {
								color: $fontcolor;
								display: block;
								position: relative;
								padding: 0;
								padding-left: 55px;
								img {
									position: absolute;
									top: 0;
									left: 0;
								}
								div {
									text-transform: uppercase;
									letter-spacing: 0.15em;
								}
								span {
									opacity: 0.5;
									font-size: 13px;
								}
								&:hover {
									div {
										color: $naranja;
									}
									span {
										opacity: 0.6;
									}
								}
							}
						}
					}
				}
			}
		}
		&.is-active {
			opacity: 1;
			visibility: visible;
			transform: translateY(0) scale(1);
		}
	}

	@media #{$break-md} {
		.navbar {
			.contw {
				nav {
					ul {
						li {
							margin-left: 20px;
						}
					}
				}
			}
		}
	}

	@media #{$break-xs} {
		.navbar {
			.contw {
				.logo {
					width: 130px;
					svg {
						width: 130px;
					}
				}
			}
		}
	}

	@media #{$break-xs} {
		.menu-overlay {
			width: calc(100% - 30px);
		}
	}

	@media #{$break-320} {
		.navbar {
			.contw {
				.logo {
					width: 110px;
					svg {
						width: 110px;
					}
				}
			}
		}
	}
}

/*
-----------------------------
Footer
-----------------------------
*/
.main-footer {
	background-color: $azul;
	padding: 60px 0 30px;
	.subscribe {
		margin: 50px 0;
		.contw {
			display: flex;
			justify-content: center;
			align-items: center;
			.campo {
				width: 100%;
				max-width: 300px;
				margin-right: 1em;
				input {
					height: 50px;
					border-radius: 50px;
				}
			}
		}
	}
	.bottom {
		h6 {
			font-size: 14px;
			letter-spacing: 1px;
			color: #fff;
			line-height: 1em;
			padding: 0;
			margin: 0 0 10px;
		}
		a {
			font-size: 14px;
			letter-spacing: 1px;
			color: #fff;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
			&.link-rojo {
				color: $naranja;
			}
		}
		.links {
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				display: block;
				color: #fff;
				margin-bottom: 10px;
				a {
					line-height: 1em;
				}
				ul {
					margin-top: 10px;
					li {
						position: relative;
						&::before {
							content: "";
							display: block;
							width: 5px;
							height: 5px;
							border-radius: 50%;
							background-color: #fff;
							position: absolute;
							top: 13px;
							left: -20px;
						}
					}
				}
			}
		}
		.contacto-f {
			a {
				display: block;
				margin: 10px 0;
			}
			.social {
				margin-top: 20px;
				a {
					display: inline-block;
					margin-right: 10px;
					&:hover {
						color: $naranja;
					}
				}
			}
		}
	}
	@media #{$break-xs} {
		.subscribe {
			.contw {
				.campo {
					margin-right: 0;
					input {
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						height: 50px;
					}
				}
				.action {
					button {
						height: 50px;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						white-space: nowrap;
						font-size: 13px;
					}
				}
			}
		}
	}
}
