@font-face {
	font-family: "DM Sans";
	src: url("../fonts/DMSans-Regular.woff2") format("woff2"),
		url("../fonts/DMSans-Regular.woff") format("woff"),
		url("../fonts/DMSans-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "DM Bold";
	src: url("../fonts/DMSans-Bold.woff2") format("woff2"),
		url("../fonts/DMSans-Bold.woff") format("woff"),
		url("../fonts/DMSans-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Neue Bold";
	src: url("../fonts/NeueMachina-Ultrabold.woff2") format("woff2"),
		url("../fonts/NeueMachina-Ultrabold.woff") format("woff"),
		url("../fonts/NeueMachina-Ultrabold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Neue Light";
	src: url("../fonts/NeueMachina-Light.woff2") format("woff2"),
		url("../fonts/NeueMachina-Light.woff") format("woff"),
		url("../fonts/NeueMachina-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Neue Regular";
	src: url("../fonts/NeueMachina-Regular.woff2") format("woff2"),
		url("../fonts/NeueMachina-Regular.woff") format("woff"),
		url("../fonts/NeueMachina-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
