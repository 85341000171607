/*
-----------------------------
General
-----------------------------
*/

body {
	font-size: 18px;
	font-family: $regular;
	overflow-x: hidden;
	color: $fontcolor;
}

p,
blockquote {
	line-height: 165%;
	strong {
		font-family: $bold;
	}
}

.contw {
	width: 100%;
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 15px;
}

.btn {
	font-family: $bold;
	border-radius: 0;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	padding: 0.7em 1em 0.7em;
	&.btn-solid {
		background-color: $azul;
		color: #fff;
		&:hover {
			color: #fff;
			background-color: lighten($azul, 5%);
		}
		&.naranja {
			background-color: $naranja;
			&:hover {
				color: #fff;
				background-color: lighten($naranja, 5%);
			}
		}
	}
	&.btn-outline {
		background-color: none;
		border: 1px solid $naranja;
		color: $naranja;
		&:hover {
			color: #fff;
			background-color: $naranja;
		}
		&.white {
			color: #fff;
			border-color: #fff;
			&:hover {
				background-color: $azul;
				border-color: $azul;
			}
		}
	}
}

.heading-s1 {
	.small {
		display: block;
		font-family: $bold;
		font-size: 14px;
		letter-spacing: 5px;
		color: $naranja;
		margin-bottom: 15px;
		text-transform: uppercase;
	}
	.title {
		font-family: $nregular;
		font-size: 48px;
		line-height: 1.2em;
		color: $fontcolor;
		strong {
			font-family: $nbold;
			color: $naranja;
		}
		span {
			color: $naranja;
		}
	}
	.lead {
		font-family: $nlight;
		font-size: 24px;
		letter-spacing: 1px;
	}
	@media #{$break-lg} {
		.title {
			font-size: 38px;
		}
	}
	@media #{$break-md} {
		.title {
			font-size: 32px;
		}
		.lead {
			font-size: 22px;
		}
	}
	@media #{$break-sm} {
		.title {
			font-size: 28px;
		}
		.lead {
			font-size: 20px;
		}
	}
}

.list-check {
	list-style-type: none;
	margin-left: 0;
	padding: 0;
	li {
		position: relative;
		line-height: 165%;
		padding-left: 35px;
		display: block;
		margin-bottom: 0.7em;
		svg {
			position: absolute;
			top: 4px;
			left: 0;
			fill: #16b34e;
		}
	}
}

.tang-table {
	.top {
		ul {
			background-color: $naranja;
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;
			flex-direction: row;
			align-items: center;
			li {
				flex: 1;
				text-align: center;
				color: #fff;
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 0.17em;
				padding: 1em 0;
			}
		}
	}
	.items {
		background: #fff;
		border: 1px solid darken($celeste, 10%);
		box-shadow: 4px 10px 20px rgba(160, 164, 167, 0.27);
		border-radius: 30px;
		.item {
			border-bottom: 1px solid darken($celeste, 10%);
			position: relative;
			padding: 0 15px;
			ul {
				list-style-type: none;
				margin: 0;
				padding: 30px 0;
				display: flex;
				flex-direction: row;
				align-items: center;
				li {
					flex: 1;
					text-align: center;
					figure {
						margin: 0;
					}
					p {
						margin: 0;
						line-height: 1.25em;
						font-size: 14px;
					}
					.principal {
						color: $naranja;
						border: 1px solid $naranja;
						border-radius: 3px;
						font-size: 12px;
						padding: 0 3px;
						margin-right: 10px;
					}
				}
			}
			a {
				position: absolute;
				top: 50%;
				right: 15px;
				transform: translateY(-50%);
			}
			&:last-child {
				border: none;
			}
		}
	}
	@media #{$break-sm} {
		.top {
			display: none;
		}
		.items {
			.item {
				ul {
					flex-wrap: wrap;
					li {
						flex: inherit;
						width: 50%;
						text-align: left;
						margin-bottom: 15px;
						&:first-child {
							width: 100%;
						}
						small {
							color: $naranja;
							font-family: $bold;
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	@media #{$break-480} {
		.items {
			.item {
				ul {
					justify-content: space-between;
					li {
						width: calc(50% - 10px);
						p {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}

/*
-----------------------------
Contacto
-----------------------------
*/
.cta-contacto {
	padding: 100px 0;
	position: relative;
	overflow: hidden;
	.info {
		a {
			color: $naranja;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		p {
			font-family: $nregular;
		}
	}
	.forma {
		background-color: $naranja;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 1000%;
			height: calc(100% + 1000px);
			position: absolute;
			top: -200px;
			left: 0;
			z-index: 1;
			background-color: $naranja;
		}
		.inner {
			padding-left: 50px;
			position: relative;
			z-index: 10;
			p {
				color: #fff;
				&.lead {
					font-family: $nregular;
					font-size: 36px;
				}
			}
			.form-group {
				label {
					font-size: 16px;
					color: #fff;
				}
			}
		}
	}
	@media #{$break-md} {
		.info {
			padding: 0 50px;
		}
		.forma {
			margin-top: 40px;
			&::before {
				top: 0;
				left: -30px;
			}
			.inner {
				padding: 50px 50px 0;
			}
		}
	}
	@media #{$break-xs} {
		.info {
			padding: 0;
		}
		.forma {
			margin-top: 40px;
			.inner {
				padding: 50px 0 0;
			}
		}
	}
}

/*
-----------------------------
Shared
-----------------------------
*/
.banner-interior {
	background-color: $naranja;
	padding: 200px 0 100px;
	position: relative;
	overflow: hidden;
	.caption {
		color: #fff;
		position: relative;
		z-index: 10;
		small {
			font-family: $bold;
			font-size: 14px;
			letter-spacing: 0.25em;
			text-transform: uppercase;
			display: block;
			margin-bottom: 15px;
		}
		h1 {
			font-family: $nregular;
			font-size: 80px;
			letter-spacing: 0.1em;
			line-height: 1.05em;
		}
		.lead {
			font-size: 24px;
			font-family: $nregular;
		}
	}
	@media #{$break-md} {
		.caption {
			h1 {
				font-size: 60px;
			}
		}
	}
	@media #{$break-md} {
		.caption {
			h1 {
				font-size: 50px;
				line-height: 1.25em;
			}
			.lead {
				font-size: 18px;
			}
		}
	}
}

.cta-proveedor {
	position: relative;
	z-index: 20;
	.block {
		transform: translateY(-50px);
		.contw {
			background-color: darken($azul, 3%);
			padding: 100px 80px;
			.caption {
				color: #fff;
			}
			.forma {
				h6 {
					font-size: 36px;
					font-family: $nregular;
					color: #fff;
				}
				p {
					color: #fff;
				}
			}
		}
	}
	@media #{$break-lg} {
		.block {
			transform: translateY(0px);
		}
	}
	@media #{$break-lg} {
		.caption {
			margin-bottom: 50px;
		}
	}
	@media #{$break-xs} {
		.block {
			.contw {
				padding: 100px 15px;
			}
		}
	}
}
